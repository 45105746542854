import { createSelector } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { cmsAPI } from './cms.api';
import { initialState } from './cms.interface';

const selectContentQuery = cmsAPI.endpoints.getContent.select(i18n.language);

export const getSupportedLanguages = createSelector(selectContentQuery, ({ data }) => {
  if (data?.languages) {
    return data.languages;
  }
  return initialState.languages;
});

export const getSplashScreen = createSelector(selectContentQuery, ({ data }) => data?.loginScreen);

export const getLogo = createSelector(selectContentQuery, ({ data }) => data?.logo);

export const getCustomerDisplaySplashScreen = createSelector(
  selectContentQuery,
  ({ data }) => data?.customerDisplaySplashScreen,
);

export const getCustomerDisplayBasketImage = createSelector(
  selectContentQuery,
  ({ data }) => data?.customerDisplayBasket,
);

export const getBestSeller = createSelector(selectContentQuery, ({ data }) => data?.bestSeller);

export const getCustomerDisplayHeaderIcon = createSelector(
  selectContentQuery,
  ({ data }) => data?.customerDisplayHeaderIcon,
);

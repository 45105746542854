import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const selectCustomer = ({ customer }: RootState) => customer;

export const getCustomerCanPayOnAccount = createSelector(
  selectCustomer,
  (customer) => customer.customerCanPayOnAccount,
);

export const getChargedCompanyWhenPayingOnAccount = createSelector(
  selectCustomer,
  (customer) => customer.chargedCompany,
);

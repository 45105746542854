import React from 'react';
import { Box } from '@mui/system';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { getSplashScreen } from 'stores/Cms/cms.selector';

type StartupWrapperProps = {
  children?: React.ReactNode;
};

const StartupWrapper: React.FC<StartupWrapperProps> = ({ children }) => {
  const [t] = useTranslation('common');
  const { loginImage } = useTheme();
  const cmsImage = useAppSelector(getSplashScreen);
  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            objectFit: cmsImage ? 'cover' : loginImage.backgroundSize,
          }}
          component="img"
          src={cmsImage ? `${cmsImage?.url}` : loginImage.url}
          alt="S4D"
        />
      </Box>
      <Box
        sx={{
          width: 615,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!children && (
          <Box sx={{ my: 4, display: 'flex' }}>
            <JumpingDotsLoader />
            <Typography sx={{ ml: 1 }} variant="h5">
              {t('Application is loading...')}
            </Typography>
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default StartupWrapper;

import { Middleware } from '@reduxjs/toolkit';
import { logOutCashier } from './Cashier';
import { resetStoreState } from './Store';

export const globalMiddleware: Middleware = (api) => (next) => (action) => {
  if (logOutCashier.fulfilled.match(action)) {
    api.dispatch(resetStoreState());
  }
  return next(action);
};

import axios from 'axios';
import { appInsights } from 'App/AppInitializationWrapper/useAppInsights';
import { SyncStatus } from 'stores/Config';
import { posApiUrls } from 'API/PosApi';
import {
  getHybridModeCommunicator,
  isHybridModeCommunicationAvailable,
} from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import { mapToAppVersionObject } from 'stores/Config/config.api';

const systemName = 'pos';

export function getLastRefreshTimestamp(): number {
  const lastRefresh = localStorage.getItem(`last-refresh-date-${systemName}`);
  if (!lastRefresh) return new Date().getTime();
  return Number(lastRefresh);
}

export function saveLastRefreshTimestamp(refreshTimestamp: number): void {
  const newLastRefresh = refreshTimestamp.toString();
  localStorage.setItem(`last-refresh-date-${systemName}`, newLastRefresh);
}

export function refreshPage(
  newestVersion: string | undefined,
  currentVersion: string,
  minutesSinceLastRefresh: number,
): void {
  try {
    const lastRefreshDate = getLastRefreshTimestamp();
    appInsights.trackEvent({
      name: `refreshInstance.${systemName}`,
      properties: { lastRefreshDate, newestVersion, currentVersion, minutesSinceLastRefresh },
    });
  } catch {
    console.warn('Error tracking page refresh');
  }
  refreshCustomerDisplay();
  window.location.reload();
}

function refreshCustomerDisplay(): void {
  try {
    const isCustomerDisplayAvailable = isHybridModeCommunicationAvailable();
    if (isCustomerDisplayAvailable) {
      const hybridModeCommunicator = getHybridModeCommunicator();
      hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.RefreshCustomerDisplay);
    }
  } catch {
    console.warn('Error sending refresh-customer-display command');
  }
}

async function getDataSyncStatus(): Promise<number | undefined> {
  const dataSyncRequest = await axios.get<SyncStatus>(posApiUrls.SYNCHRONIZATION_STATUS);
  if (!dataSyncRequest || dataSyncRequest.status !== 200) return undefined;
  return new Date(dataSyncRequest?.data?.lastSuccessfulSyncTimestamp).getTime();
}

async function getNewestAppVersion(): Promise<string | undefined> {
  const versionRequest = await axios.get<string>(posApiUrls.DEV_APP_VERSION, {
    params: {
      timestamp: new Date().getTime(), // this way we always skip browser cache
    },
  });
  if (!versionRequest || versionRequest.status !== 200) return undefined;
  const newestVersion = mapToAppVersionObject(versionRequest.data);
  return newestVersion.baseVersion;
}

async function handleAppVersionCheck(currentVersion: string | undefined) {
  if (!currentVersion || currentVersion.length === 0) return undefined;
  const newestVersion = await getNewestAppVersion();
  if (!newestVersion || newestVersion.length === 0) return undefined;
  if (newestVersion === currentVersion) return undefined;

  return newestVersion;
}

async function handleAppContentCheck() {
  const lastDataSync = await getDataSyncStatus();
  const lastPOSRefresh = getLastRefreshTimestamp();
  if (!lastDataSync) return -30; // if no refresh was done lets assume that 30 minute passed so content needs to be refreshed
  return (lastPOSRefresh - lastDataSync) / (60 * 1000);
}

export async function handleCheck(currentVersion: string) {
  const newestVersion = await handleAppVersionCheck(currentVersion);
  const minutesSinceLastSync = await handleAppContentCheck();

  const versionNeedsUpdate = newestVersion && newestVersion !== currentVersion;
  const contentsNeedsUpdate = minutesSinceLastSync < -1;

  if (versionNeedsUpdate || contentsNeedsUpdate) refreshPage(newestVersion, currentVersion, minutesSinceLastSync);
}

import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { BrowserHistory } from 'history';
import { useAppSelector } from 'hooks/useRedux';
import { getLoggingCorrelationId } from 'stores/Config/config.selector';
import { useEffect } from 'react';
import { generateW3CId } from '@microsoft/applicationinsights-core-js';
import { useGetConfigQuery } from 'stores/Config/config.api';

type UseAppInsightsProps = {
  reactPlugin: ReactPlugin;
  browserHistory: BrowserHistory;
};

type AppInsights = {
  instance: ApplicationInsights | null;
  instrumentationKey: string | null;
  trackEvent: (event: { name: string; properties?: any }) => void;
};

// this would normally go to a ref, but we need to access it outside react context and this makes it more convenient
export const appInsights: AppInsights = {
  instance: null,
  instrumentationKey: null,
  trackEvent: (event) => {
    try {
      appInsights.instance?.trackEvent(event);
    } catch (error) {
      console.error('Could not push data to azure app insights:', error);
    }
  },
};

export const useAppInsights = ({ reactPlugin, browserHistory }: UseAppInsightsProps) => {
  const { isSuccess: configLoaded, data: configData } = useGetConfigQuery();
  const { instrumentationKey } = (configLoaded && configData) || {};
  const loggingCorrelationId = useAppSelector(getLoggingCorrelationId);

  useEffect(() => {
    const needsFirstInitialization = !!instrumentationKey && !appInsights.instance;
    const needsReinitialization =
      !needsFirstInitialization && instrumentationKey !== appInsights.instrumentationKey;

    if (needsReinitialization) {
      appInsights.instance?.flush();
    }

    if (instrumentationKey && (needsFirstInitialization || needsReinitialization)) {
      const newAppInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          enableAutoRouteTracking: true,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
          },
        },
      });
      newAppInsights.loadAppInsights();
      newAppInsights.trackPageView();
      newAppInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
        // eslint-disable-next-line no-param-reassign
        envelope.tags = envelope.tags || [];
        envelope.tags.push({ 'ai.cloud.role': 'offline-module-ui' });
      });

      newAppInsights.context.telemetryTrace.traceID = generateW3CId();
      appInsights.instance = newAppInsights;
      appInsights.instrumentationKey = instrumentationKey;
    }
  }, [browserHistory, instrumentationKey, reactPlugin]);

  useEffect(() => {
    if (appInsights.instance && loggingCorrelationId) {
      appInsights.instance.flush();
      appInsights.instance.context.telemetryTrace.traceID = generateW3CId();
    }
  }, [loggingCorrelationId]);
};

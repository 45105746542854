import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing }) => {
  return {
    footer: {
      display: 'flex',
      flexDirection: 'row',
      padding: `${spacing(1)}`,
      borderTop: `1px solid ${palette.divider}`,
      width: sizing.full,
    },
  };
});

export default buildClasses;

import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18n from 'i18next';
import {
  CMS_BASE_URL,
  CMS_CONTROLLERS,
  CMS_HEADERS,
  CMS_TIMEOUT,
  CmsCustomerDisplay,
  CmsLanguage,
  CmsPosImages,
  i18nResource,
} from 'API/CmsApi';
import { StaticContent, SupportedUILanguage } from './cms.interface';

const cmsBaseQuery = fetchBaseQuery({
  baseUrl: CMS_BASE_URL,
  credentials: 'include',
  headers: new Headers(CMS_HEADERS),
  prepareHeaders: (headers) => {
    headers.set('Accept-Language', `${i18n.language}`);
    return headers;
  },
  timeout: CMS_TIMEOUT,
});

export const cmsAPI = createApi({
  reducerPath: 'cmsAPI',
  baseQuery: cmsBaseQuery,
  endpoints: (builder) => ({
    getContent: builder.query<StaticContent, string>({
      queryFn: async (locale, api, extraOptions, baseQuery) => {
        const [images, customerDisplay, supportedLanguages, translations] = await Promise.all([
          baseQuery({
            url: CMS_CONTROLLERS.IMAGES,
            params: { locale },
          }),
          baseQuery({
            url: CMS_CONTROLLERS.CUSTOMER_DISPLAY,
            params: { locale },
          }),
          baseQuery({
            url: CMS_CONTROLLERS.LOCALES,
            params: undefined,
          }),
          baseQuery({
            url: CMS_CONTROLLERS.LABELS,
            params: { locale },
          }),
        ]);

        if (images.error) return { error: images.error as FetchBaseQueryError };
        if (customerDisplay.error) return { error: customerDisplay.error as FetchBaseQueryError };
        if (supportedLanguages.error) return { error: supportedLanguages.error as FetchBaseQueryError };
        if (translations.error) return { error: translations.error as FetchBaseQueryError };

        const imagesData = images.data as CmsPosImages;
        const customerDisplayData = customerDisplay.data as CmsCustomerDisplay;
        const supportedLanguagesData = (supportedLanguages.data as CmsLanguage[]).map((cmsLang) => {
          return {
            code: cmsLang.code,
            name: cmsLang.name,
            isDefault: cmsLang.isDefault,
          } as SupportedUILanguage;
        });
        const translationsData = translations.data as i18nResource[];

        return {
          data: {
            loginScreen: imagesData.splashScreen,
            logo: imagesData.logo,
            bestSeller: imagesData.bestSeller,
            customerDisplayBasket: customerDisplayData.basketImage,
            customerDisplaySplashScreen: customerDisplayData.splashScreen,
            customerDisplayHeaderIcon: customerDisplayData.headerIcon,
            languages: supportedLanguagesData,
            translations: translationsData,
          } as StaticContent,
        };
      },
      providesTags: ['Translations'],
    }),
  }),
  tagTypes: ['Translations'],
});

export const { useGetContentQuery } = cmsAPI;

import { RootState } from 'stores';
import { createSelector } from '@reduxjs/toolkit';
import { storeApi } from './store.api';

const selectStore = (state: RootState) => state.stores;

export const getSelectedStore = createSelector(selectStore, (stores) => stores.selectedStore);

export const getAvailableSwitchStores = createSelector(
  storeApi.endpoints.getSwitchStores.select(),
  (result) => result.data,
);

export const getSwitchedStore = createSelector(
  [getSelectedStore, getAvailableSwitchStores],
  (selectedStore, availableSwitchStores) => availableSwitchStores?.find((el) => el.id === selectedStore?.id),
);

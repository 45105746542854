import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { BasketItem } from 'typings/Basket';
import { Coupon, Meal, MealSettings } from 'typings/Coupons';
import {
  Category,
  CustomizationProduct,
  Option,
  Product,
  SliceCustomization,
  SubOption,
  Topping,
} from 'typings/Products';
import { OrderDeliveryAddress, ProfileAddress } from './intakeTypes';

export function compareAlphabetically(titleA: string, titleB: string): number {
  if (titleA.toLowerCase() > titleB.toLowerCase()) return 1;
  if (titleA.toLowerCase() < titleB.toLowerCase()) return -1;
  return 0;
}

export function compareGeneric(objectA: any, objectB: any, fieldToCompare: string): number {
  if (objectA[fieldToCompare].toLowerCase() > objectB[fieldToCompare].toLowerCase()) return 1;
  if (objectA[fieldToCompare].toLowerCase() < objectB[fieldToCompare].toLowerCase()) return -1;
  return 0;
}
export function sortBy(productList: Array<Product>): Array<Product> {
  return [...productList].sort((a, b) => compareGeneric(a, b, 'Name'));
}

export function sortProductsAlphabetically(productList: Array<Product>): Array<Product> {
  return [...productList].sort((a, b) => compareAlphabetically(a.name, b.name));
}

export function sortProductsBySortOrder(productList: Array<Product>): Array<Product> {
  return [...productList].sort((a, b) => a.sortOrder - b.sortOrder);
}

export function sortSubOptionsAlphabetically(subOptionsList: Array<SubOption>): Array<SubOption> {
  return [...subOptionsList].sort((a, b) => compareAlphabetically(a.description, b.description));
}

export function assignProductsToCategory(products: Array<Product>, categoryCode: string): Product[] {
  return products.filter((product) => product.categoryCode === categoryCode);
}
export function sortProductWithCategories(products: Product[], categories: Category[]): Category[] {
  return categories.map((category) => {
    const categoryProducts = assignProductsToCategory(products, category.code);
    return { ...category, products: categoryProducts };
  });
}

export function fillCategoryImageUrlInProducts(products: Product[], categories: Category[]): Product[] {
  return categories.flatMap((category) => {
    const categoryProducts = assignProductsToCategory(products, category.code);
    return categoryProducts.map((cPrd) => {
      return { ...cPrd, categoryImageUrl: category.imageUrl };
    });
  });
}

export function removeProductsWithoutOptions(products: Product[]): Product[] {
  return products.filter((product) => product.options.length !== 0);
}

export function removeEmptyCategories(categories: Category[]): Category[] {
  return categories.filter((category) => {
    if (category.products) return category.products.length !== 0 || category.code === 'BSL';

    return false;
  });
}

export function removeEmptyOptions(options: Option[], products: Product[]): Option[] {
  return options.filter((option) =>
    products.find((product) => product.options.some((opt) => opt.productOptionId === option.id)),
  );
}

export function removeOptionsWithoutProducts(options: Option[], products: Product[]): Option[] {
  return options.filter((opt) => products.some((pr) => pr.options.some((po) => po.productOptionId === opt.id)));
}

export function filterOptionsByActiveSubOption(options: Option[], activeSubOptionId: number | null): Option[] {
  if (activeSubOptionId === null) return options;
  return options.filter((option) => option.optionSubTypeId === activeSubOptionId);
}

export function filterProductsByActiveOption(products: Product[], activeOptionId: number | null): Product[] {
  return products.filter((product) => {
    return product.options && product.options.some((option) => option.productOptionId === activeOptionId);
  });
}

export function filterProductsBySearchString(
  products: Product[],
  toppings: Topping[],
  options: Option[],
  subOptions: SubOption[],
  searchString: string,
): Product[] {
  function textIncludedsSearchString(searchElement: string) {
    return searchElement.toLowerCase().includes(searchString.toLowerCase());
  }

  const filteredSubOptionIds = subOptions.reduce<number[]>((ids, subOpt) => {
    if (textIncludedsSearchString(subOpt.description)) {
      ids.push(subOpt.id);
    }
    return ids;
  }, []);

  const filteredOptionIds = options.reduce<number[]>((ids, opt) => {
    if (
      textIncludedsSearchString(opt.name) ||
      textIncludedsSearchString(opt.title) ||
      filteredSubOptionIds.some((subId) => subId === opt.optionSubTypeId)
    ) {
      ids.push(opt.id);
    }
    return ids;
  }, []);

  const filteredToppingIds = toppings.reduce<number[]>((ids, top) => {
    if (textIncludedsSearchString(top.name) || textIncludedsSearchString(top.title)) {
      ids.push(top.id);
    }
    return ids;
  }, []);

  const filteredProducts = products.filter(
    (product) =>
      textIncludedsSearchString(product.title) ||
      product.options.some((productOption) =>
        filteredOptionIds.some(
          (optId) => optId === productOption.productOptionId && productOption.isPriceAvailable,
        ),
      ) ||
      product.defaultToppings.some((defaultTopping) =>
        filteredToppingIds.some((topId) => topId === defaultTopping.toppingId),
      ) ||
      product.properties.some((prop) => textIncludedsSearchString(prop.propertyName)),
  );
  return filteredProducts;
}

export function filterCouponsBySearchString(coupons: Coupon[], searchString: string): Coupon[] {
  return coupons.filter((coupon) => coupon.description.toLowerCase().includes(searchString.toLowerCase()));
}
export function filterProductsSubOptions(
  products: Product[],
  options: Option[],
  subOptions: SubOption[],
  categoryCode: string,
): SubOption[] {
  const productOptions = products
    .filter((product) => product.categoryCode === categoryCode)
    .flatMap((product) => product.options);
  const filteredOptions = options.filter((option) =>
    productOptions.find((productOption) => productOption.productOptionId === option.id),
  );
  return subOptions
    .filter((subOption) => filteredOptions.find((option) => option.optionSubTypeId === subOption.id))
    .sort((a, b) => {
      const sortOrderA = filteredOptions.find((option) => option.optionSubTypeId === a.id)?.sortOrder || 0;
      const sortOrderB = filteredOptions.find((option) => option.optionSubTypeId === b.id)?.sortOrder || 0;
      return sortOrderA - sortOrderB;
    });
}

export function orderItemsByIndex(currentBasket: BasketItem[]): BasketItem[] {
  return [...currentBasket].sort((a, b) => (a.id > b.id ? 1 : -1));
}

export function mapToManyMeals(
  mealSettings: MealSettings[],
  categories: Category[],
  products: Product[],
  options: Option[],
): Meal[] {
  return mealSettings
    .map((setting) => {
      const mappedMeals: Meal[] = [];

      for (let i = 0; i < setting.quantity; ) {
        const canChangeProduct = setting.allowedProductsIds.length > 1;
        const optionsIds = setting.canChangeOption || !setting.optionId ? undefined : [setting.optionId];

        const meal = {
          categoryName: categories.find((cat) => cat.code === setting.categoryCode)?.name,
          availableProducts: products.filter((prod) => setting.allowedProductsIds.includes(prod.id)),
          customizationRestrictions: {
            hideQuantity: true,
            canChangeProduct,
            canChangeOption: setting.canChangeOption,
            allowedOptionsIds: setting.allowedOptionsIds ?? optionsIds,
          },
          defaultOptionId: setting.optionId,
        } as Meal;

        if (setting.productId !== undefined) {
          const baseProduct =
            products.find((prod) => prod.id === setting.productId) ??
            (products.find((prod) =>
              prod.options.some((opt) => opt.productOptionId === setting.optionId),
            ) as Product);

          if (!baseProduct) {
            meal.selectedProduct = undefined;
          } else {
            const initialSliceCustomization: SliceCustomization[] = [
              {
                sliceProductId: baseProduct?.id,
                sliceProductName: baseProduct?.name,
                toppingsConfiguration: baseProduct?.defaultToppings,
                addedToppings: [],
                removedToppings: [],
              },
            ];
            const originalOptionAvailable = baseProduct.options.some(
              (el) => el.productOptionId === setting.optionId,
            );
            const preselectedOptionId = originalOptionAvailable
              ? setting.optionId
              : baseProduct.options[0].productOptionId;
            meal.selectedProduct = {
              quantity: 1,
              baseProduct,
              originalOptionId: preselectedOptionId,
              optionName: options.find((opt) => opt.id === preselectedOptionId)?.name,
              sliceCustomizations: baseProduct.isXTasty ? undefined : initialSliceCustomization,
            } as CustomizationProduct;
          }
        }

        mappedMeals.push(meal);
        i += 1;
      }

      return mappedMeals;
    })
    .flat();
}

export function mapFormDataToDeliveryAddress(data: CheckoutDetailsForm): OrderDeliveryAddress {
  return {
    street: data.street,
    streetNumber: data.streetNumber,
    streetNumberAddition: data.streetNumberAddition,
    buildingName: data.buildingName,
    entranceNumber: data.entranceNumber,
    apartmentNumber: data.apartmentNumber,
    streetType: data.streetType,
    zipcode: data.zipcode,
    city: data.city,
    district: data.district,
    addressType: data.addressType,
    addressRemark: data.addressRemark,
    suggestionBox: data.suggestionBox,
    region: data.region,
  };
}

export function mapProfileAddressToDeliveryAddress(profileAddress: ProfileAddress): OrderDeliveryAddress {
  return {
    street: profileAddress.street,
    streetNumber: profileAddress.streetNumber,
    streetNumberAddition: profileAddress.streetNumberAddition,
    buildingName: profileAddress.buildingName,
    entranceNumber: profileAddress.entranceNumber,
    apartmentNumber: profileAddress.apartmentNumber,
    streetType: profileAddress.streetType,
    zipcode: profileAddress.zipcode,
    city: profileAddress.city,
    district: profileAddress.district,
    addressType: profileAddress.addressType,
    region: profileAddress.region,
    id: profileAddress.id,
  };
}

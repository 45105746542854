import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AvailableIntakeContents, IntakeStatuses, PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { PaymentMethodCode } from 'typings/Payments';
import { mapToSelectedCustomer } from 'utils/intake/IntakeUtils';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { editOrder } from 'stores/AllOrders';
import { MachineType } from 'typings/Auth';
import {
  getDineInOrder,
  postFinalizeDineInOrder,
  postPlaceUnpaidDineInOrder,
  updateDineInOrder,
} from 'stores/DineIn/dineIn-thunk.actions';
import {
  getDeliveryAreaDetails,
  getLocalizationDetails,
  getOrderHistory,
  postFinishDeliveryOrder,
  postFinishPickupOrder,
  postFinishTakeAwayOrder,
  postPlaceUnpaidTakeAwayOrder,
  saveDeliveryOrderEdition,
  savePickupOrderEdition,
} from './intake.thunk-actions';
import {
  CustomerSearchResultItem,
  IntakeState,
  OnSiteSettings,
  OrderDeliveryAddress,
  OrderDeliveryTime,
} from './intakeTypes';
import { intakeApi } from './intake.api';

const intialOnSiteSettings: OnSiteSettings = {
  defaultDeliveryType: PickUpTypesValues.pickUp,
  excludedDeliveryTypes: [],
  machineName: '',
  isEatInPromptEnabledForToGo: false,
  onSiteMachineTypeId: MachineType.Registry,
  isToGoModeEnabled: false,
};

export const initialState: IntakeState = {
  activeDeliveryType: PickUpTypesValues.pickUp,
  activeIntakeTab: AvailableIntakeContents.Products,
  finalizeTrigger: false,
  intakeStatus: IntakeStatuses.productsSection,
  isEatIn: false,
  onSiteSettings: intialOnSiteSettings,
};

const intakeSlice = createSlice({
  name: '[INTAKE]',
  initialState,
  reducers: {
    restartIntakeState: (state) => {
      const activeDeliveryType = state.onSiteSettings?.defaultDeliveryType ?? initialState.activeDeliveryType;
      return {
        ...initialState,
        onSiteSettings: state.onSiteSettings,
        activeDeliveryType:
          activeDeliveryType === PickUpTypesValues.eatIn ? PickUpTypesValues.takeAway : activeDeliveryType,
        isEatIn: activeDeliveryType === PickUpTypesValues.eatIn,
      };
    },
    setIntakeState: (state, action: PayloadAction<IntakeState>) => ({
      ...action.payload,
      onSiteSettings: state.onSiteSettings,
    }),
    setLastOrderChangeAmount: (state, action: PayloadAction<number | undefined>) => ({
      ...state,
      lastOrderChangeAmount: action.payload,
    }),
    setOrderRemarks: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      remarks: action.payload,
    }),
    setManualPriceOverride: (state, action: PayloadAction<number | undefined>) => ({
      ...state,
      manualPriceOverride: action.payload,
    }),
    setActiveDeliveryType: (state, action: PayloadAction<PickUpTypesValues>) => ({
      ...state,
      activeDeliveryType: action.payload,
    }),
    setActivePaymentMethod: (state, action: PayloadAction<PaymentMethodCode | undefined>) => ({
      ...state,
      activePaymentMethod: action.payload,
    }),
    setManualDeliveryCharge: (state, action: PayloadAction<number | null>) => ({
      ...state,
      manualDeliveryCharge: action.payload,
    }),
    setIntakeCustomer: (state, action: PayloadAction<CustomerSearchResultItem | undefined>) => ({
      ...state,
      selectedOrderCustomer: action.payload && mapToSelectedCustomer(action.payload),
    }),
    setManualDeliveryAddress: (state, action: PayloadAction<OrderDeliveryAddress | undefined>) => ({
      ...state,
      manuallyFilledAddress: action.payload,
    }),
    setSelectedDeliveryTime: (state, action: PayloadAction<OrderDeliveryTime | undefined>) => ({
      ...state,
      selectedDeliveryTime: action.payload,
    }),
    clearAddresses: (state) => ({
      ...state,
      manuallyFilledAddress: undefined,
    }),
    setActiveIntakeTab: (state, action: PayloadAction<AvailableIntakeContents>) => ({
      ...state,
      activeIntakeTab: action.payload,
    }),
    setIsEatIn: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isEatIn: action.payload,
    }),
    setFinalizeTrigger(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        finalizeTrigger: action.payload,
      };
    },
    setIntakeStatus: (state, action: PayloadAction<IntakeStatuses>) => ({
      ...state,
      intakeStatus: action.payload,
    }),
    setPhoneNumberSearchQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      phoneNumberSearchQuery: action.payload,
    }),
    clearPhoneNumberSearchQuery: (state) => ({
      ...state,
      phoneNumberSearchQuery: undefined,
    }),
    setTableTag: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      tableTag: action.payload,
    }),
    setCustomerSearchQuery: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      customerSearchQuery: action.payload,
    }),
    clearEditRequestStatus: (state) => ({
      ...state,
      editRequestStatus: undefined,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderHistory.fulfilled, (state) => ({
      ...state,
      lastOrderChangeAmount: undefined,
    }));
    builder.addCase(postPlaceUnpaidDineInOrder.pending, (state) => ({
      ...state,
      orderPlacementStatus: 'pending',
    }));
    builder.addCase(postPlaceUnpaidDineInOrder.rejected, (state) => ({
      ...state,
      orderPlacementStatus: 'failure',
    }));
    builder.addCase(postPlaceUnpaidDineInOrder.fulfilled, (state) => ({
      ...state,
      orderPlacementStatus: 'success',
    }));
    builder.addCase(postFinishTakeAwayOrder.pending, (state) => ({
      ...state,
      orderPlacementStatus: 'pending',
    }));
    builder.addCase(postFinishTakeAwayOrder.rejected, (state) => ({
      ...state,
      orderPlacementStatus: 'failure',
    }));
    builder.addCase(postFinishTakeAwayOrder.fulfilled, (state) => ({
      ...state,
      orderPlacementStatus: 'success',
    }));
    builder.addCase(postPlaceUnpaidTakeAwayOrder.pending, (state) => ({
      ...state,
      orderPlacementStatus: 'pending',
    }));
    builder.addCase(postPlaceUnpaidTakeAwayOrder.rejected, (state) => ({
      ...state,
      orderPlacementStatus: undefined,
    }));
    builder.addCase(postPlaceUnpaidTakeAwayOrder.fulfilled, (state) => ({
      ...state,
      orderPlacementStatus: undefined,
    }));
    builder.addCase(postFinishDeliveryOrder.pending, (state) => ({
      ...state,
      orderPlacementStatus: 'pending',
    }));
    builder.addCase(postFinishDeliveryOrder.rejected, (state) => ({
      ...state,
      orderPlacementStatus: 'failure',
    }));
    builder.addCase(postFinishDeliveryOrder.fulfilled, (state) => ({
      ...state,
      orderPlacementStatus: 'success',
    }));
    builder.addCase(postFinishPickupOrder.pending, (state) => ({
      ...state,
      orderPlacementStatus: 'pending',
    }));
    builder.addCase(postFinishPickupOrder.rejected, (state) => ({
      ...state,
      orderPlacementStatus: 'failure',
    }));
    builder.addCase(postFinishPickupOrder.fulfilled, (state) => ({
      ...state,
      orderPlacementStatus: 'success',
    }));
    builder.addCase(postFinalizeDineInOrder.pending, (state) => ({
      ...state,
      orderPlacementStatus: 'pending',
    }));
    builder.addCase(postFinalizeDineInOrder.rejected, (state) => ({
      ...state,
      orderPlacementStatus: 'failure',
    }));
    builder.addCase(postFinalizeDineInOrder.fulfilled, (state) => ({
      ...state,
      orderPlacementStatus: 'success',
    }));
    builder.addCase(getDineInOrder.fulfilled, (state) => ({
      ...state,
      activeDeliveryType: PickUpTypesValues.dineIn,
      manualPriceOverride: undefined,
    }));
    builder.addCase(updateDineInOrder.fulfilled, () => ({
      ...initialState,
    }));

    builder.addCase(recalculateBasket.fulfilled, (state, action) => {
      if (state.editMode && action.payload?.changeEditState) {
        return {
          ...state,
          editMode: {
            ...state.editMode,
            basketEdited: true,
          },
        };
      }
      return state;
    });
    builder.addCase(editOrder.pending, (state) => ({
      ...state,
      editRequestStatus: 'pending',
    }));
    builder.addCase(editOrder.rejected, (state) => ({
      ...state,
      editRequestStatus: 'failure',
    }));
    builder.addCase(editOrder.fulfilled, (state) => ({
      ...state,
      editRequestStatus: 'success',
    }));

    builder.addCase(saveDeliveryOrderEdition.pending, (state) => ({
      ...state,
      editRequestStatus: 'pending',
    }));
    builder.addCase(saveDeliveryOrderEdition.fulfilled, (state) => ({
      ...state,
      editRequestStatus: 'success',
    }));
    builder.addCase(saveDeliveryOrderEdition.rejected, (state) => ({
      ...state,
      editRequestStatus: 'failure',
    }));

    builder.addCase(savePickupOrderEdition.pending, (state) => ({
      ...state,
      editRequestStatus: 'pending',
    }));
    builder.addCase(savePickupOrderEdition.fulfilled, (state) => ({
      ...state,
      editRequestStatus: 'success',
    }));
    builder.addCase(savePickupOrderEdition.rejected, (state) => ({
      ...state,
      editRequestStatus: 'failure',
    }));
    builder.addCase(getLocalizationDetails.fulfilled, (state, action) => ({
      ...state,
      localizationDetails: action.payload,
    }));
    builder.addCase(getDeliveryAreaDetails.fulfilled, (state, action) => {
      return {
        ...state,
        deliveryAreaDetails: action.payload,
      };
    });
    builder.addMatcher(intakeApi.endpoints.getOnSiteSettings.matchFulfilled, (state, action) => {
      const activeDeliveryType = action.payload?.defaultDeliveryType;
      return {
        ...state,
        onSiteSettings: action.payload,
        activeDeliveryType:
          activeDeliveryType === PickUpTypesValues.eatIn ? PickUpTypesValues.takeAway : activeDeliveryType,
        isEatIn: activeDeliveryType === PickUpTypesValues.eatIn,
      };
    });
  },
});

const { reducer } = intakeSlice;
export const {
  restartIntakeState,
  setLastOrderChangeAmount,
  setOrderRemarks,
  setManualPriceOverride,
  setActiveDeliveryType,
  setActivePaymentMethod,
  setIntakeCustomer,
  clearAddresses,
  setActiveIntakeTab,
  setIsEatIn,
  setFinalizeTrigger,
  setManualDeliveryAddress,
  setIntakeStatus,
  setIntakeState,
  setPhoneNumberSearchQuery,
  clearPhoneNumberSearchQuery,
  setTableTag,
  clearEditRequestStatus,
  setCustomerSearchQuery,
} = intakeSlice.actions;

export default reducer;

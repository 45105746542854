import { useTranslation } from 'react-i18next';
import { LocalStorageItems, getLocalStorageItem } from 'utils/localStorageUtils';
import { useAppDispatch } from 'hooks/useRedux';
import {
  getHybridModeCommunicator,
  isHybridModeCommunicationAvailable,
} from 'utils/hybridMode/hybridModeCommunicationUtils';
import {
  BasketRecalculated,
  CloudPosAvailabilityChangedEvent,
  CloudPosLastConnectionAttemptEvent,
  HybridModeActivityChangedEvent,
  HybridModeMessages,
  LanguageChanged,
  PhoneCalled,
  TransferredIntake,
} from 'typings/HybridMode';
import {
  setIsHybridModeActive,
  setPhoneNumberCalling,
  setTransferredIntake,
  updateCloudPosAvailabilityStatus,
  updateCloudPosLastConnectionAttempt,
} from 'stores/HybridMode';
import {
  hideIntakeFinalizeSuccessMessage,
  setCashierIsAway,
  showIntakeFinalizeSuccessMessage,
  updateCustomerDisplayBasket,
} from 'stores/CustomerDisplay';
import { useEffect } from 'react';

type UseHybridCommunicationProps = {
  configLoaded: boolean;
};

export const useHybridCommunication = ({ configLoaded }: UseHybridCommunicationProps) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const hybridModeCommunicationAvailable = isHybridModeCommunicationAvailable();

  useEffect(() => {
    if (!hybridModeCommunicationAvailable || !configLoaded) {
      return;
    }
    const hybridModeCommunicator = getHybridModeCommunicator();
    const isCustomerDisplayEnabled = getLocalStorageItem<boolean>(LocalStorageItems.useCustomerDisplay) ?? false;
    if (isCustomerDisplayEnabled) {
      hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.OpenCustomerDisplay);
    }

    setInterval(() => {
      hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.PosRunning);
    }, 1000);

    hybridModeCommunicator.subscribe(
      HybridModeMessages.Hybrid.Events.HybridModeActivityChanged,
      (payload: string) => {
        const hybridModeActivity = JSON.parse(payload) as HybridModeActivityChangedEvent;

        dispatch(setIsHybridModeActive(hybridModeActivity));
      },
    );

    hybridModeCommunicator.subscribe(
      HybridModeMessages.Hybrid.Events.CloudPosAvailabilityChanged,
      (payload: string) => {
        const cloudPosAvailability = JSON.parse(payload) as CloudPosAvailabilityChangedEvent;

        dispatch(updateCloudPosAvailabilityStatus(cloudPosAvailability?.isAvailable ?? false));
      },
    );

    hybridModeCommunicator.subscribe(
      HybridModeMessages.Hybrid.Events.CloudPosLastConnectionAttemptEvent,
      (payload: string) => {
        const lastAttempt = JSON.parse(payload) as CloudPosLastConnectionAttemptEvent;

        if (lastAttempt) {
          dispatch(updateCloudPosLastConnectionAttempt(new Date(lastAttempt.timestampInMiliseconds)));
        }
      },
    );

    hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Commands.RestoreBasket, (payload: string) => {
      const intakeStateToTransfer = JSON.parse(payload) as TransferredIntake;

      if (intakeStateToTransfer) {
        dispatch(setTransferredIntake(intakeStateToTransfer));
      }
    });

    hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.BasketRecalculated, (payload: string) => {
      const basketRecalculatedEvent = JSON.parse(payload) as BasketRecalculated;

      if (basketRecalculatedEvent) {
        dispatch(updateCustomerDisplayBasket(basketRecalculatedEvent));
        dispatch(hideIntakeFinalizeSuccessMessage());
      }
    });

    hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.IntakeFinalized, () => {
      dispatch(showIntakeFinalizeSuccessMessage());
    });

    hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.CashierLoggedIn, () => {
      dispatch(setCashierIsAway());
    });

    hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.CashierLoggedOut, () => {
      dispatch(setCashierIsAway(true));
    });

    hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.LanguageChanged, (payload: string) => {
      const languageChangedEvent = JSON.parse(payload) as LanguageChanged;

      if (languageChangedEvent) {
        i18n.changeLanguage(languageChangedEvent.languageCode);
      }
    });

    hybridModeCommunicator.subscribe(HybridModeMessages.Hybrid.Events.PhoneCalled, (payload: string) => {
      const phoneCalledEvent = JSON.parse(payload) as PhoneCalled;

      if (phoneCalledEvent) {
        dispatch(setPhoneNumberCalling(phoneCalledEvent.phoneNumber));
      }
    });
  }, [configLoaded, dispatch, hybridModeCommunicationAvailable, i18n]);
};

import generateCodeChallenge from './cryptography';

export const prepareCodeChallenge = async () => {
  const { codeChallenge, codeVerifier } = await generateCodeChallenge();
  localStorage.setItem('code_verifier', codeVerifier);
  localStorage.setItem('code_challenge', codeChallenge);
};

export const destroyCodeChallenge = (): void => {
  localStorage.removeItem('code_verifier');
  localStorage.removeItem('code_challenge');
};

import { Theme } from '@mui/material/styles';
import brandLogo from 'assets/logos/KOTI/logo.svg';

import { BASE_THEME } from './base.theme.css';

const KOTI_COLORS = {
  primary: {
    main: '#007734',
    light: '#43784A',
    dark: '#1b3819',
    contrastText: '#fff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#D9534F',
  },
  success: {
    light: '#81c784',
    main: '#007734',
    dark: '#388e3c',
    contrastText: '#fff',
  },
};

export const KOTI_THEME = {
  ...BASE_THEME,
  brandLogo,
  loginImage: {
    url: brandLogo,
    backgroundSize: 'contain',
  },
  palette: {
    ...BASE_THEME.palette,
    ...KOTI_COLORS,
    green: {
      ...BASE_THEME.palette.green,
      '100': '#F1FFEC',
    },
    appBar: {
      main: '#007734',
      subMain: '#098B42',
      subMainText: '#fff',
    },
  },
  virtualReceipt: {
    items: {
      discount: {
        ...BASE_THEME.palette.virtualReceipt.items.discount,
        error: {
          icon: 'rgb(255,41,41)',
          background: KOTI_COLORS.error.light,
        },
      },
    },
  },
  transitions: {
    ...BASE_THEME.transitions,
    create: () => 'none',
  },
  components: {
    ...BASE_THEME.components,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*, *::before, *::after': {
          transition: 'none !important',
          animation: 'none !important',
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        hidden: false,
      },
      styleOverrides: {
        root: {
          color: BASE_THEME.palette.text.primary,
        },
      },
    },
  },
} as Theme;

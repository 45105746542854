import React from 'react';
import { Box, useTheme } from '@mui/material';

interface DisabledOverlayProps {
  show?: boolean;
}

const DisabledOverlay = ({ show }: DisabledOverlayProps) => {
  const { palette, sizing, zIndex } = useTheme();

  if (!show) return null;

  return (
    <Box
      sx={{
        backgroundColor: palette.white,
        opacity: 0.5,
        position: 'absolute',
        width: sizing.full,
        height: sizing.full,
        zIndex: zIndex.fab,
        top: 0,
        left: 0,
      }}
    />
  );
};

export default DisabledOverlay;

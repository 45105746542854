import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import {
  fillCategoryImageUrlInProducts,
  removeEmptyCategories,
  removeEmptyOptions,
  removeProductsWithoutOptions,
  sortProductWithCategories,
  sortProductsAlphabetically,
  sortProductsBySortOrder,
} from 'stores/Intake/IntakeStoreUtils';
import { BasketItem, RecalculateBasketItemDto, RecalculateBasketItemResponseDto } from 'typings/Basket';
import { Coupon, MealSettings } from 'typings/Coupons';
import {
  BestSellerProduct,
  CategoriesWithProducts,
  Category,
  Customization,
  CustomizationProduct,
  Group,
  Option,
  Product,
  ProductOptionPrice,
  ProductTopping,
  RequestProductOptionPrices,
  SliceCustomization,
  SubOption,
} from 'typings/Products';
import { isValidAxiosResponse } from 'typings/type-guards';
import { createSliceCustomizations } from 'utils/intake/xTastyUtils';
import { getIntakeSettings } from '../Config/config.selector';

export const getCustomizationProductSettings = createAsyncThunk<
  CustomizationProduct | undefined,
  { basketItem: BasketItem; searchType: 'itemId' | 'group' },
  { state: RootState }
>('[PRODUCTS]/getCustomizationProductSettings', async ({ basketItem, searchType }, { getState }) => {
  const { basketCoupons } = getState().basket;
  const { products } = getState().products;

  const associatedMealCoupon = basketCoupons.find(
    (c) => c.linkedBaskedItemIds && c.linkedBaskedItemIds.includes(basketItem.id) && c.useMealConfigurator,
  );

  const baseProduct = products.find((el) => el.id === basketItem.itemId);
  let restrictions;
  if (baseProduct && associatedMealCoupon) {
    restrictions = await getRestrictions(basketItem, associatedMealCoupon, searchType, baseProduct);
  }

  if (baseProduct) {
    const initialSliceCustomization: SliceCustomization[] = [
      {
        sliceProductId: baseProduct?.id,
        sliceProductName: baseProduct?.name,
        toppingsConfiguration: baseProduct?.defaultToppings,
        addedToppings: [],
        removedToppings: [],
      },
    ];
    return {
      baseProduct,
      quantity: basketItem.quantity,
      originalOptionId: basketItem.optionId,
      basketItemGuid: basketItem.basketItemGuid,
      basketItemIndex: basketItem.id,
      sliceCustomizations: basketItem.sliceCustomizations ?? initialSliceCustomization,
      remark: basketItem.remark,
      stepSelections: basketItem.selectedSetSteps,
      restrictions,
    };
  }

  return undefined;
});

async function getRestrictions(
  basketItem: BasketItem | Product,
  associatedMealCoupon: Coupon,
  searchType: 'itemId' | 'group',
  product: Product,
) {
  try {
    const result = await posApi.get<MealSettings[]>(
      posApiUrls.DISCOUNT_MEAL_SETTINGS(associatedMealCoupon.couponCode),
    );

    // Order of basket items is in meal coupon is the same as the order of product-specific settings
    const productSettingsIndex = associatedMealCoupon.linkedBaskedItemIds.indexOf(basketItem.id);
    const matchingSetting =
      searchType === 'itemId'
        ? result.data[productSettingsIndex]
        : result.data.find(
            (f) => f.categoryCode === product.categoryCode && (!f.groupCode || f.groupCode === product.groupCode),
          );

    if (matchingSetting === undefined) return undefined;

    const canChangeProduct = matchingSetting.allowedProductsIds.length > 1;
    const optionsIds = matchingSetting.optionId ? [matchingSetting.optionId] : undefined;

    return {
      hideQuantity: true,
      canChangeProduct,
      canChangeOption: matchingSetting.canChangeOption,
      allowedOptionsIds: matchingSetting.canChangeOption ? matchingSetting.allowedOptionsIds : optionsIds,
    };
  } catch (error) {
    console.error(`Error getting filteres for coupon code: ${associatedMealCoupon?.couponCode}, error:`, error);
    throw error;
  }
}

export const fetchProductOptionPrices = createAsyncThunk(
  '[PRODUCTS]/fetchProductOptionPrices',
  async (request: RequestProductOptionPrices) => {
    const result = await posApi.get<ProductOptionPrice[]>(
      posApiUrls.OPTION_PRICES(request.pickupType, request.productId),
    );
    return result.data;
  },
);

export const fetchCategoriesWithProducts = createAsyncThunk<CategoriesWithProducts, void, { state: RootState }>(
  '[PRODUCTS]/fetchCategoriesWithProducts',
  async (_, { getState }) => {
    const intakeSettings = getIntakeSettings(getState());
    const categoriesResult = await posApi.get<Category[]>(posApiUrls.PRODUCTS_CATEGORIES_GET);
    const productsResult = await posApi.get<Product[]>(posApiUrls.PRODUCTS_GET);
    const products = intakeSettings?.sortProductsByName
      ? sortProductsAlphabetically(productsResult.data)
      : sortProductsBySortOrder(productsResult.data);
    const productsWithCategoryImage = fillCategoryImageUrlInProducts(products, categoriesResult.data);

    const productsWithOptions = removeProductsWithoutOptions(productsWithCategoryImage);
    const sortedCategories = sortProductWithCategories(productsWithOptions, categoriesResult.data);
    const sortedFilledCategories = removeEmptyCategories(sortedCategories);

    return { productsWithOptions, sortedFilledCategories };
  },
);

export const fetchAllProductGroups = createAsyncThunk('[PRODUCTS]/fetchAllProductGroups', async () => {
  const result = await posApi.get<Group[]>(posApiUrls.PRODUCTS_GROUPS_GET);

  return result.data;
});

export const fetchAllProductOptions = createAsyncThunk<Option[], void, { state: RootState }>(
  '[PRODUCTS]/fetchAllProductOptions',
  async (_, { getState }) => {
    const { products } = getState().products;
    const result = await posApi.get<Option[]>(posApiUrls.PRODUCTS_OPTIONS_GET);

    return removeEmptyOptions(result.data, products);
  },
);

export const fetchAllProductSubtypes = createAsyncThunk('[PRODUCTS]/fetchAllProductSubtypes', async () => {
  const result = await posApi.get<SubOption[]>(posApiUrls.PRODUCTS_SUBTYPES_GET);
  return result.data;
});

export const fetchBestSellerProducts = createAsyncThunk<BestSellerProduct[], void, { state: RootState }>(
  '[PRODUCTS]/fetchBestSellerProducts',
  async (_, { getState }) => {
    const { products } = getState().products;

    const result = await posApi.get<BestSellerProduct[]>(posApiUrls.BEST_SELLER_PRODUCTS_GET);

    return result.data.map((bstPrd) => ({
      ...bstPrd,
      product: products.find((prd) => prd.id === bstPrd.product.id) ?? bstPrd.product,
    }));
  },
);

export const getCustomizedItemPrice = createAsyncThunk<
  number,
  {
    productCustomizations: Customization[];
    defaultToppings: ProductTopping[];
    quantity: number;
    productId: number;
    productOptionId: number;
  },
  { state: RootState }
>(
  '[PRODUCTS]/getItemRecalculation',
  async (
    { quantity, productId, productOptionId, productCustomizations, defaultToppings },
    { getState, rejectWithValue },
  ) => {
    const { basket, intake } = getState();
    const { basketItems } = basket;
    const { activeDeliveryType, isEatIn } = intake;

    const basketItem: RecalculateBasketItemDto = {
      item: {
        id: basketItems.length + 1,
        quantity,
        productSelection: {
          productId,
          productOptionId,
        },
        sliceCustomizations: createSliceCustomizations(productCustomizations, defaultToppings),
      },
      pickupType: activeDeliveryType,
      isEatIn,
    };
    const result = await posApi.put<RecalculateBasketItemResponseDto>(
      posApiUrls.BASKET_ITEM_RECALCULATE,
      basketItem,
    );

    if (!isValidAxiosResponse(result)) {
      return rejectWithValue((result as any)?.response?.data);
    }

    return result.data.itemPriceCalculationResult.total.originalGrossValue;
  },
);

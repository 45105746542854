import { Theme } from '@mui/material/styles';
import brandLogo from 'assets/logos/CILIPIZZA/logo.svg';
import { sizing } from './sizing';

import { BASE_THEME } from './base.theme.css';

const CILI_PIZZA_COLORS = {
  ...BASE_THEME.palette,
  primary: {
    main: '#FFDE13',
    light: '#FFF6C2',
    dark: '#17191B',
    contrastText: '#fff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
    main: '#E8E3D1',
    contrastText: '#000',
  },
  text: {
    ...BASE_THEME.palette.text,
    secondary: '#000',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#FF003D',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff',
  },
};

export const CILI_PIZZA_THEME = {
  ...BASE_THEME,
  brandLogo,
  loginImage: {
    url: brandLogo,
    backgroundSize: 'contain',
  },
  palette: {
    ...BASE_THEME.palette,
    ...CILI_PIZZA_COLORS,

    grey: {
      ...BASE_THEME.palette.grey,
      '100': '#f5f5f5',
      '200': '#EAEAEA',
      '500': '#A9A9A9',
      '600': '#8E8E8E',
    },
    green: {
      ...BASE_THEME.palette.green,
      '100': '#F1FFEC',
    },
    products: {
      groups: {
        active: {
          background: CILI_PIZZA_COLORS.primary.light,
          border: CILI_PIZZA_COLORS.primary.main,
        },
      },
      categories: {
        active: {
          label: CILI_PIZZA_COLORS.secondary.contrastText,
        },
      },
    },
    appBar: {
      main: '#17191B',
      subMain: '#2C3539',
      subMainText: '#fff',
      active: CILI_PIZZA_COLORS.primary.main,
    },
  },
  virtualReceipt: {
    items: {
      discount: {
        ...BASE_THEME.palette.virtualReceipt.items.discount,
        error: {
          icon: 'rgb(255,41,41)',
          background: CILI_PIZZA_COLORS.error.light,
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiButton: {
      ...BASE_THEME.components?.MuiButton,
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: BASE_THEME.palette.black.main,
            '&:hover': {
              backgroundColor: '#FFEB70',
              boxShadow: 'none',
            },
            '&:focus': {
              backgroundColor: '#FFEB70',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            color: BASE_THEME.palette.black.main,
            '&:hover': {
              backgroundColor: '#F7F4E7',
              boxShadow: 'none',
            },
            '&:focus': {
              backgroundColor: '#F7F4E7',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'iconButton' },
          style: {
            minWidth: `${sizing.spacingBase * 6}px`,
            padding: `${sizing.spacingBase}px`,
            borderRadius: '2px',
            background: '#E8E3D1',
            '&:hover': {
              backgroundColor: '#F7F4E7',
            },
            '&:focus': {
              backgroundColor: '#F7F4E7',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: BASE_THEME.palette.black.main,
            backgroundColor: '#E8E3D1',
            '&:hover': {
              backgroundColor: '#F7F4E7',
              border: 'none',
            },
            '&:focus': {
              backgroundColor: '#F7F4E7',
              border: 'none',
            },
            '&:disabled': {
              backgroundColor: '#DBE1E3',
              border: 'none',
            },
            border: 'none',
          },
        },
      ],
    },
  },
} as Theme;

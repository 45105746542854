import { TFunction } from 'i18next';

interface TimeSpanComponents {
  days: number;
  hours: number;
  minutes: number;
}

export default function timeSpanToSeconds(timeSpan: string): number {
  const trimmedTimeSpan = `${timeSpan?.trim()}`;
  if (trimmedTimeSpan.length === 0) return 0;

  // assume that TimeSpan is hh:mm:ss
  const values = trimmedTimeSpan.split(':');

  const minFromHours = parseFloat(values[0]) * 3600;
  const minFromMinutes = parseFloat(values[1]) * 60;
  const minFromSeconds = parseFloat(values[2]);

  return Math.round(minFromHours + minFromMinutes + minFromSeconds);
}

export function timeSpanToMinutes(timeSpan: string | null): number {
  const trimmedTimeSpan = `${timeSpan?.trim()}`;
  if (trimmedTimeSpan.length === 0) return 0;
  return Math.floor(timeSpanToSeconds(trimmedTimeSpan) / 60);
}

export function minutesToTimeSpan(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours < 10 ? '0' : ''}${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}:00`;
}

export const parseTimeSpan = (waitTime: string): TimeSpanComponents => {
  if (!waitTime) {
    return { days: 0, hours: 0, minutes: 0 };
  }

  const timeSpanRegex = /^\s*(?:(-?\d+)\.)?(\d{1,2}):([0-5]\d):([0-5]\d)(?:\.(\d{1,7}))?\s*$/;

  const match = waitTime.match(timeSpanRegex);

  if (!match) {
    return { days: 0, hours: 0, minutes: 0 };
  }

  const [, daysStr = '0', hoursStr, minutesStr] = match;

  const days = parseInt(daysStr, 10) || 0;
  const hours = parseInt(hoursStr, 10) || 0;
  const minutes = parseInt(minutesStr, 10) || 0;

  return {
    days,
    hours,
    minutes,
  };
};

export const formatTimeSpan = (waitTime: string, t: TFunction): string => {
  const { days, hours, minutes } = parseTimeSpan(waitTime);

  if (days === 0 && hours === 0 && minutes === 0) {
    return t('Future');
  }

  const parts: string[] = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0 || parts.length === 0) parts.push(`${minutes}m`);

  return parts.join('');
};

export function formatDateToDisplay(value: Date, culture: string, timeZone?: string): string {
  if (timeZone) {
    return value.toLocaleDateString(culture, { timeZone });
  }
  return value.toLocaleDateString(culture);
}

export function formatDateTimeToDisplay(value: Date, culture: string, timeZone?: string): string {
  if (timeZone) {
    return `${value.toLocaleDateString(culture, { timeZone })} ${value.toLocaleTimeString(culture, { timeZone })}`;
  }

  return `${value.toLocaleDateString(culture)} ${value.toLocaleTimeString(culture)}`;
}

export function formatTimeToDisplay(value: Date, culture: string, timeZone?: string): string {
  if (timeZone) {
    return value.toLocaleTimeString(culture, { timeZone });
  }
  return value.toLocaleTimeString(culture);
}
export function isDeliveryDateToday(date: string): boolean {
  const today = new Date();
  const deliveryDate = new Date(date);

  return today.toDateString() === deliveryDate.toDateString();
}

export const formatDeliveryOrCollectionTime = (
  isAsap: boolean | undefined,
  time: string | undefined,
): string | undefined => {
  if (isAsap || typeof time === 'undefined') {
    return undefined;
  }
  const trimmed = time.trim();
  if (/^\d{1,2}:\d{2}$/.test(trimmed)) {
    return `${time}:00`;
  }
  return trimmed;
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { RootState } from 'stores';
import posApi, { posApiUrls } from 'API/PosApi';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import { AuthorizationDto, Cashier, CashierDetailsDto, EmployeeInfo } from 'typings/Cashier';
import { posBaseQuery } from '../base.queries';
import { getFeatureFlags } from '../Config/config.selector';
import { intakeApi } from '../Intake/intake.api';

const loginToStore = async (pinCode: string) => {
  await posApi.get('/Online/Account/Switch', {
    withCredentials: true,
    headers: { 'X-Origin-Module': 'online-pos' },
  });

  await posApi.post(
    '/Online/Account/Switch',
    {
      ReturnUrl: '/Registry',
      PinCodeInput: pinCode,
      SwitchType: 'unlock',
    },
    {
      timeout: 10000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Origin-Module': 'online-pos',
      },
    },
  );
};

export const cashierAPI = createApi({
  reducerPath: 'cashierAPI',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getClockedInUsers: builder.query<EmployeeInfo[], void>({
      query: () => posApiUrls.ACCOUNT_CLOCKED_IN_USERS,
      providesTags: ['ClockedInUsers'],
    }),
    getLoggedInCashier: builder.query<Cashier, void>({
      query: () => posApiUrls.USER_GET_DATA,
      transformResponse: (baseQueryReturnValue: CashierDetailsDto): Cashier => {
        cashierAPI.util.invalidateTags(['ClockedInUsers']);
        return {
          id: baseQueryReturnValue.userId,
          firstName: baseQueryReturnValue.givenName,
          lastName: baseQueryReturnValue.familyName,
          roleName: baseQueryReturnValue.mainRole,
          permissions: baseQueryReturnValue.permissions,
        };
      },
      providesTags: ['LoggedInCashier'],
    }),
    logInCashier: builder.mutation<Cashier, string>({
      queryFn: async (pinCode, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: posApiUrls.ACCOUNT_LOGIN,
          method: 'POST',
          body: { Secret: pinCode },
        });

        if (result.error) return { error: result.error };

        const featureFlags = getFeatureFlags(api.getState() as RootState);
        if (featureFlags.OfflineModule_SingleNavigation) {
          await loginToStore(pinCode);
        }

        await api.dispatch(intakeApi.endpoints.getOnSiteSettings.initiate(undefined, { forceRefetch: true }));

        const hybridModeCommunicator = getHybridModeCommunicator();
        hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.CashierLoggedIn);

        const data = result.data as AuthorizationDto;

        return {
          data: {
            id: data.userId,
            firstName: data.givenName,
            lastName: data.familyName,
            permissions: [],
          },
        };
      },
      invalidatesTags: ['LoggedInCashier'],
    }),
    clockInCashier: builder.mutation<AuthorizationDto, string>({
      query: (pinCode) => ({
        url: posApiUrls.ACCOUNT_CLOCK_IN,
        method: 'POST',
        body: { Secret: pinCode },
      }),
    }),
    endBreak: builder.mutation<void, void>({
      query: () => ({
        url: posApiUrls.ACCOUNT_END_BREAK,
        method: 'PUT',
      }),
      invalidatesTags: ['ClockedInUsers', 'LoggedInCashier'],
    }),
  }),
  tagTypes: ['LoggedInCashier', 'ClockedInUsers'],
});

export const {
  useGetClockedInUsersQuery,
  useLazyGetLoggedInCashierQuery,
  useEndBreakMutation,
  useLogInCashierMutation,
  useClockInCashierMutation,
} = cashierAPI;

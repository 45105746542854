import { Theme } from '@mui/material/styles';
import brandLogo from 'assets/logos/FIREAWAY/logo.svg';

import { BASE_THEME } from './base.theme.css';

const FIREAWAY_COLORS = {
  primary: {
    main: '#E95714',
    light: '#FBD2BA',
    dark: '#17191B',
    contrastText: '#ffffff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#FF003D',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff',
  },
};

export const FIREAWAY_THEME = {
  ...BASE_THEME,
  brandLogo,
  loginImage: {
    url: brandLogo,
    backgroundSize: 'contain',
  },
  palette: {
    ...BASE_THEME.palette,
    ...FIREAWAY_COLORS,

    grey: {
      ...BASE_THEME.palette.grey,
      '100': '#f5f5f5',
      '200': '#EAEAEA',
      '500': '#A9A9A9',
      '600': '#8E8E8E',
    },
    green: {
      ...BASE_THEME.palette.green,
      '100': '#F1FFEC',
    },
    products: {
      groups: {
        active: {
          background: FIREAWAY_COLORS.primary.light,
          border: FIREAWAY_COLORS.primary.main,
        },
      },
      categories: {
        active: {
          label: FIREAWAY_COLORS.primary.main,
        },
      },
    },
    appBar: {
      main: '#17191B',
      subMain: '#2C3539',
      subMainText: '#fff',
      active: FIREAWAY_COLORS.primary.main,
    },
  },
  virtualReceipt: {
    items: {
      discount: {
        ...BASE_THEME.palette.virtualReceipt.items.discount,
        error: {
          icon: 'rgb(255,41,41)',
          background: FIREAWAY_COLORS.error.light,
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiButton: {
      ...BASE_THEME.components?.MuiButton,
      variants: [
        ...(BASE_THEME.components?.MuiButton?.variants || []),
        {
          props: { variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: '#ee9268',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: '#DBE1E3',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              border: 'none',
            },
            border: 'none',
          },
        },
      ],
    },
  },
} as Theme;

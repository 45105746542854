import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { StoreInfo, SwitchStore } from 'typings/Store';
import { EtaConfiguration } from 'typings/Notifications';
import { posApiUrls } from 'API/PosApi';
import { posBaseQuery } from '../base.queries';
import { restartIntakeState } from '../Intake';
import { restartDineInState } from '../DineIn/dineIn.slice';
import { resetProductsState } from '../Products';
import { resetFloorsState } from '../Floors/floors.slice';
import { resetCouponsState } from '../Coupons';
import { restartLocalOrdersState } from '../LocalOrders';
import { cashierAPI } from '../Cashier/cashier.api';
import { getNoOfOrdersToConfirmEta } from '../Notifications/notifications.thunk-actions';
import { updateEtaConfiguration } from '../Notifications';

export const storeApi = createApi({
  reducerPath: 'storeApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getSelectedStore: builder.query<StoreInfo, void>({
      query: () => posApiUrls.SELECTED_STORE_GET,
      providesTags: ['SelectedStore'],
    }),
    getSwitchStores: builder.query<SwitchStore[], void>({
      query: () => posApiUrls.SWITCH_STORES,
      providesTags: ['SwitchStores'],
    }),
    switchStore: builder.mutation<StoreInfo, string>({
      queryFn: async (storeId, api, extraOptions, baseQuery) => {
        // TODO: move to global listener
        api.dispatch(restartIntakeState());
        api.dispatch(restartDineInState());
        api.dispatch(resetProductsState());
        api.dispatch(resetFloorsState());
        api.dispatch(resetCouponsState());
        api.dispatch(restartLocalOrdersState());
        api.dispatch(cashierAPI.endpoints.getClockedInUsers.initiate(undefined, { forceRefetch: true }));

        const result = await baseQuery({
          url: `${posApiUrls.SWITCH_STORES}/${storeId}`,
          method: 'PUT',
        });

        if (result.error) return { error: result.error as FetchBaseQueryError };

        // TODO: move to global listener
        api.dispatch(getNoOfOrdersToConfirmEta());

        return {
          data: result.data as StoreInfo,
        };
      },
    }),
    updateStoreEtaConfiguration: builder.mutation<null, { storeId: number; config: EtaConfiguration }>({
      queryFn: async ({ storeId, config }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: posApiUrls.SYNCHRONIZATION_ETA(storeId),
          method: 'PUT',
          body: {
            PickUpEtaMinutes: config.pickUpEtaMinutes,
            DeliveryEtaMinutes: config.deliveryEtaMinutes,
          },
        });

        if (result.error) return { error: result.error as FetchBaseQueryError };

        // TODO: move to global listener
        api.dispatch(updateEtaConfiguration(config));

        return {
          data: null,
        };
      },
    }),
  }),
  tagTypes: ['SwitchStores', 'SelectedStore'],
});

export const {
  useUpdateStoreEtaConfigurationMutation,
  useSwitchStoreMutation,
  useGetSwitchStoresQuery,
  useGetSelectedStoreQuery,
} = storeApi;

const escapeBase64 = (str: string): string => {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

const bufferToBase64 = (buffer: ArrayBuffer): string => {
  const digestArray = Array.from(new Uint8Array(buffer));
  return escapeBase64(window.btoa(String.fromCharCode(...digestArray)));
};

const randomBytes = (length: number): ArrayBuffer => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return array;
};

const sha256 = (message: string): Promise<ArrayBuffer> => {
  const msgUint8 = new TextEncoder().encode(message);
  return window.crypto.subtle.digest('SHA-256', msgUint8);
};

type ChallengeData = {
  codeVerifier: string;
  codeChallenge: string;
};

export default async (): Promise<ChallengeData> => {
  const codeVerifier = bufferToBase64(randomBytes(32));
  const codeChallenge = bufferToBase64(await sha256(codeVerifier));

  return {
    codeVerifier,
    codeChallenge,
  };
};

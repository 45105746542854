import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DineInRefreshMessage, EtaConfiguration, NotificationsState, OrderingOption } from 'typings/Notifications';
import uuidGenerator from 'utils/GuidGenerator';

const initialState: NotificationsState = {
  noOfOrdersToConfirmEta: 0,
};

const notificationsSlice = createSlice({
  name: '[NOTIFY]',
  initialState,
  reducers: {
    updateLastDineInRefreshMessage(state, action: PayloadAction<DineInRefreshMessage>) {
      return { ...state, lastDineInRefreshMessage: action.payload };
    },
    setNotificationsConnectionId(state, action: PayloadAction<string>) {
      return { ...state, notificationsConnectionId: action.payload };
    },
    disconnectNotifications(state) {
      return { ...state, lastDineInRefreshMessage: undefined, notificationsConnectionId: undefined };
    },
    reconnectToNotifications(state) {
      return { ...state, reconnectionSentinelFlag: uuidGenerator() };
    },
    updateNoOfOrdersToConfirmEta(state, action: PayloadAction<number>) {
      return { ...state, noOfOrdersToConfirmEta: action.payload };
    },
    updateEtaConfiguration(state, action: PayloadAction<EtaConfiguration>) {
      return { ...state, etaConfiguration: action.payload };
    },
    updateOrderingOption(state, action: PayloadAction<OrderingOption>) {
      return { ...state, orderingOption: action.payload };
    },
  },
});

const { reducer } = notificationsSlice;
export const {
  updateLastDineInRefreshMessage,
  setNotificationsConnectionId,
  disconnectNotifications,
  reconnectToNotifications,
  updateNoOfOrdersToConfirmEta,
  updateEtaConfiguration,
  updateOrderingOption,
} = notificationsSlice.actions;

export default reducer;

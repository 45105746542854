import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import DevModePanel from 'App/DevMode/DevModePanel';

const DevModeContext = createContext<IDevMode>({
  showDevUI: false,
  setShowDevUI: () => null,
});

type IDevMode = {
  showDevUI: boolean;
  setShowDevUI: (v: boolean) => void;
};

type DevModeProviderProps = {
  children: React.ReactNode;
};

const DevModeProvider: React.FC<DevModeProviderProps> = ({ children }) => {
  const [showDevUI, setShowDevUI] = useState<boolean>(false);

  const data = useMemo(
    () => ({
      showDevUI,
      setShowDevUI,
    }),
    [showDevUI, setShowDevUI],
  );

  const onClose = useCallback(() => setShowDevUI(!showDevUI), []);

  useEffect(() => {
    document.onkeydown = (e: KeyboardEvent) => {
      const isDevelopmentMode = process.env.NODE_ENV === 'development';

      if (isDevelopmentMode) {
        if (e?.key?.toLowerCase() === 'b' && e?.ctrlKey) {
          setShowDevUI(!showDevUI);
        }
      }
    };

    return () => {
      document.onkeydown = () => null;
    };
  }, [showDevUI]);

  return (
    <DevModeContext.Provider value={data}>
      <Drawer sx={{ zIndex: 1300 }} anchor="right" open={showDevUI} onClose={onClose}>
        <Box sx={{ minWidth: '35vw', p: 5 }}>
          <Typography variant="h6">{'Developer Toolbar'}</Typography>
          <DevModePanel />
        </Box>
      </Drawer>
      {children}
    </DevModeContext.Provider>
  );
};

export { DevModeContext, DevModeProvider };

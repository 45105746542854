import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'typings/Auth/index';
import { destroyCodeChallenge } from './codeChallenge';

export const LocalStorageKeys = {
  USER_TOKEN: 'user_token',
  USER_ID: 'user_id',
} as const;

// Return type indicates if callback should be run
export const processToken = (token: string): boolean => {
  if (!window.opener) {
    token && saveToken(token);
    return true;
  }
  if (!token) {
    window.opener.postMessage(
      {
        type: 'ERROR_GETTING_TOKEN',
        message: 'No Access Token Found.',
      },
      window.location.origin,
    );
    window.close();
    return false;
  }

  window.opener.postMessage(
    {
      type: 'ACCESS_TOKEN',
      accessToken: token,
    },
    window.location.origin,
  );
  window.close();
  return false;
};

export const clearToken = (): void => {
  Object.keys(LocalStorageKeys).forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const saveToken = (token: string): void => {
  const decodedToken = jwtDecode(token) as DecodedToken;
  if (!decodedToken) {
    clearToken();
  }
  localStorage.setItem(LocalStorageKeys.USER_TOKEN, token);
  localStorage.setItem(LocalStorageKeys.USER_ID, decodedToken.sub);
};

export const isTokenValid = (): boolean => {
  // ensures backwards compatibility with old tokens
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode(token) as DecodedToken;
  if (!decodedToken) {
    return false;
  }
  return Date.now() < Number(decodedToken.exp) * 1000;
};

export const getAuthorizationHeader = (): string => {
  if (!isTokenValid()) {
    throw new Error('Token is not valid or expired');
  }
  return `Bearer ${localStorage.getItem(LocalStorageKeys.USER_TOKEN)}`;
};

export const logOut = (): void => {
  destroyCodeChallenge();
  clearToken();
};

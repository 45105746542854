import { createSlice } from '@reduxjs/toolkit';
import { EftError, EftState, ExecutionResultStatus } from 'typings/Payments';
import { postPlaceUnpaidTakeAwayOrder } from 'stores/Intake';
import {
  cancelEftPayment,
  getEftPaymentStatus,
  getEftPaymentTerminal,
  handleEftStatusNotification,
  startEftPayment,
} from './eft.thunk-actions';

const initialState: EftState = {};

const eftSlice = createSlice({
  name: '[EFT]',
  initialState,
  reducers: {
    clearEftData: () => ({
      ...initialState,
    }),
    clearEftResult: (state) => ({
      ...state,
      error: undefined,
      success: undefined,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(startEftPayment.pending, (state, action) => ({
      ...state,
      error: undefined,
      success: undefined,
      eftPaymentId: undefined,
      cancelInProgress: false,
      startTransactionInProgress: true,
    }));
    builder.addCase(startEftPayment.fulfilled, (state, action) => ({
      ...state,
      eftPaymentId: action.meta.arg.identifier,
      error: undefined,
      success: undefined,
      startTransactionInProgress: false,
    }));
    builder.addCase(startEftPayment.rejected, (state, action) => ({
      ...state,
      eftPaymentId: undefined,
      error: action.payload as string,
      success: undefined,
      startTransactionInProgress: false,
    }));
    builder.addCase(getEftPaymentStatus.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.status === ExecutionResultStatus.Pending) {
          return { ...state };
        }

        if (action.payload.status === ExecutionResultStatus.Succeeded) {
          return {
            ...state,
            success: true,
          };
        }
      }
      return {
        ...state,
        eftPaymentId: undefined,
        error: action.payload?.responseMessage ?? EftError.EftError,
        cancelInProgress: false,
      };
    });
    builder.addCase(getEftPaymentStatus.rejected, (state, action) => ({
      ...state,
      eftPaymentId: undefined,
      error: action.payload as string,
      cancelInProgress: false,
    }));
    builder.addCase(handleEftStatusNotification.fulfilled, (state) => ({
      ...state,
      success: true,
      cancelInProgress: false,
    }));
    builder.addCase(handleEftStatusNotification.rejected, (state, action) => ({
      ...state,
      eftPaymentId: undefined,
      error: action.payload as string,
      cancelInProgress: false,
    }));
    builder.addCase(getEftPaymentTerminal.fulfilled, (state, action) => ({
      ...state,
      eftPaymentTerminal: action.payload?.deviceId ? action.payload : 'not-configured',
    }));
    builder.addCase(getEftPaymentTerminal.rejected, (state) => ({
      ...state,
      eftPaymentTerminal: 'not-configured',
    }));
    builder.addCase(cancelEftPayment.pending, (state) => ({
      ...state,
      cancelInProgress: true,
    }));
    builder.addCase(cancelEftPayment.rejected, (state) => ({
      ...state,
      cancelInProgress: false,
    }));
    builder.addCase(postPlaceUnpaidTakeAwayOrder.rejected, (state) => ({
      ...state,
      error: 'Error placing take away order',
    }));
  },
});

const { reducer } = eftSlice;

export const { clearEftData, clearEftResult } = eftSlice.actions;

export default reducer;

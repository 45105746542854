import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { FinalizationTimeData, PaymentMethodCode } from 'typings/Payments';
import { LocalStorageItems, getLocalStorageItem } from 'utils/localStorageUtils';
import { PaymentConfiguration } from 'stores/Config';
import { OrderPayment } from './orderPayment.slice';
import { getPaymentConfig, getQuickPaymentValuesConfig } from '../Config/config.selector';

// This does not belong here, but with the (architecturally wrong) dependency on the combined actions moving it to right place will cause a dependency loop
const selectPayments = ({ payments }: RootState) => payments;
export const getShowSettlePayment = createSelector(selectPayments, (payments) => !!payments?.settlePayment);

export const getShowOrderPaymentModal = ({ orderPayment }: RootState) => !!orderPayment.showOrderPayment;

export const getPaymentModalToShow = createSelector(
  [getShowOrderPaymentModal, getShowSettlePayment],
  (showNewPaymentModal, showOldPaymentModal) => {
    if (showNewPaymentModal) return 'newModal';
    if (showOldPaymentModal) return 'oldModal';
    return undefined;
  },
);
export const getActivePayment = ({ orderPayment }: RootState) => orderPayment.payments.find((el) => el.isActive);
export const getOrderPayment = ({ orderPayment }: RootState) => orderPayment;
export const getIsAnyOrderActionPending = ({ orderPayment }: RootState) => {
  if (orderPayment.acceptanceInProgress) return true;
  if (orderPayment.cancelInProgress) return true;
  if (orderPayment.orderPaymentStatusInProgress) return true;
  if (orderPayment.orderFinalizationInProgress) return true;
  return false;
};

export const getIsActivePaymentCashType = createSelector([getActivePayment], (activePayment) => {
  if (!activePayment) return false;
  // PaymentMethodCode.Cash should be replaced with some value from backend
  return activePayment.paymentMethod?.code === PaymentMethodCode.Cash;
});

export const getIsSplitPaymentAvailableFor = createSelector(
  (state: RootState) => ({ payment: getPaymentConfig(state), orderPayment: getOrderPayment(state) }),
  ({ payment, orderPayment }) => {
    return (
      payment?.v2.splitPaymentConfiguration.enableSplitPayment &&
      orderPayment.deliveryType != null &&
      !payment?.v2.splitPaymentConfiguration.excludedPickupTypes.includes(orderPayment.deliveryType)
    );
  },
);

export const getFinalizationTimeDataSelector = createSelector(
  (state: RootState) => ({ payment: getPaymentConfig(state), orderPayment: getOrderPayment(state) }),
  ({ payment, orderPayment }) =>
    (): FinalizationTimeData => {
      return getFinalizationTimeData(orderPayment, payment);
    },
);

export const getFinalizationTimeData = (
  orderPayment: OrderPayment,
  payment: PaymentConfiguration,
): FinalizationTimeData => {
  const activePayment = orderPayment.payments.find((el) => el.isActive);

  if (!payment?.v2.finalizeAutomaticallyForPaymentMethods.some((e) => e === activePayment?.paymentMethod?.id))
    return { skipFinalizationScreen: false, finalizationTime: undefined };

  const skipFinalizePaymentStep =
    getLocalStorageItem<boolean>(LocalStorageItems.skipFinalizePaymentStep) ??
    payment?.v2.skipFinalizePaymentStep ??
    false;

  if (skipFinalizePaymentStep) return { skipFinalizationScreen: true, finalizationTime: undefined };

  const finalizationTime =
    getLocalStorageItem<number>(LocalStorageItems.finalizePaymentTime) ??
    payment?.v2.automaticFinalizationDefaultTime ??
    3;

  return { skipFinalizationScreen: false, finalizationTime };
};

export const getActivePaymentQuickValues = createSelector(
  [getActivePayment, getQuickPaymentValuesConfig],
  (activePayment, quickValues) => () => {
    if (!activePayment?.paymentMethod) {
      return [];
    }
    return (
      quickValues?.paymentMethodQuickValues?.find((item) => item.code === activePayment.paymentMethod?.code)
        ?.values ?? []
    );
  },
);

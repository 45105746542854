import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { initialState } from './auth.slice';

const selectSelf = (state: RootState) => state;

export const authSelector = createSelector(selectSelf, (state) => state.authorization);

export const getInitialStores = createSelector(
  authSelector,
  (state) => state.initialStores ?? initialState.initialStores,
);

export const postPageRedirectStatusSelector = createSelector(
  authSelector,
  (state) => state.postPageRedirectStatus,
);

export const onsiteMachineSelector = createSelector(authSelector, (state) => state.onsiteMachine);

import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useGetContentQuery } from 'stores/Cms/cms.api';

const CmsHandler: React.FC = () => {
  const { isSuccess, data } = useGetContentQuery(i18n.language);
  const { translations, languages } = data || {};

  useEffect(() => {
    if (isSuccess && !!translations) {
      translations.forEach((labels) => {
        i18n.addResourceBundle(labels.locale, labels.namespace, labels.resource);
      });
    }
  }, [isSuccess, translations]);

  useEffect(() => {
    if (isSuccess && !!languages) {
      const currentLang = i18n.language;
      if (!languages.some((el) => el.code === currentLang)) {
        const defaultLang = languages.find((el) => el.isDefault)?.code ?? languages[0].code;
        i18n.changeLanguage(defaultLang);
      }
    }
  }, [isSuccess, languages]);

  return null;
};

export default CmsHandler;

import React, { useCallback, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import StartupWrapper from 'App/Routes/DefaultCompontent';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import './styleOverride.css';
import AlertModal from 'containers/Shared/AlertModal/AlertModal';
import AlertSnackbar from 'containers/Shared/Alert/AlertSnackbar';
import getThemeByKey from 'services/ThemeService';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ClearBasketOnPhonecallDialog from 'components/Intake/Phonecall/ClearBasketOnPhonecallDialog';
import { setPhoneNumberSearchQuery } from 'stores/Intake';
import { clearPhoneNumberCalling } from 'stores/HybridMode';
import { DevModeProvider } from 'App/DevMode/DevModeProvider';
import { FiscalContextProvider } from 'App/AppEventsTracker/AppEventsTracker';
import { restartOrder } from 'stores/combined.actions';
import { useGetConfigQuery } from 'stores/Config/config.api';
import { getInstanceType, getShowFirstTimeLaunch, getThemeKey } from 'stores/Config/config.selector';
import AppInitializationWrapper from './AppInitializationWrapper/AppInitializationWrapper';
import Routes from './Routes/Routes';
import ConnectionLostDialog from './HybridMode/ConnectionLostDialog';
import ConnectionReestablishedDialog from './HybridMode/ConnectionReestablishedDialog';
import { useGlobalInterceptors } from './useGlobalInterceptors';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const App = (): JSX.Element => {
  useGlobalInterceptors();

  const [openConnectionLostDialog, setOpenConnectionLostDialog] = React.useState(false);
  const [openConnectionReestablishedDialog, setOpenConnectionReestablishedDialog] = React.useState(false);
  const [basketIsEmpty, setBasketIsEmpty] = React.useState(true);
  const [theme, setTheme] = React.useState<Theme>(getThemeByKey('S4D'));

  const { isSuccess: configLoaded } = useGetConfigQuery();
  const showFirstTimeLaunch = useAppSelector(getShowFirstTimeLaunch);
  const themeKey = useAppSelector(getThemeKey);
  const instanceType = useAppSelector(getInstanceType);

  const { isHybridModeActive, isCloudPosAvailable, phoneNumberCalling, offlineModeEnabled } = useAppSelector(
    (store) => store.hybridMode,
  );
  const { selectedOrderCustomer } = useAppSelector(({ intake }) => intake);
  const { basketItems, basketCoupons } = useAppSelector((state) => state.basket);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (configLoaded && themeKey) {
      setTheme(getThemeByKey(themeKey));
    }
  }, [configLoaded, themeKey]);

  useEffect(() => {
    if (!configLoaded || !isHybridModeActive || isCloudPosAvailable === undefined) {
      return;
    }

    if (instanceType === 'InStore' && isCloudPosAvailable) {
      setOpenConnectionReestablishedDialog(true);
    }

    if (instanceType === 'Central') {
      setOpenConnectionLostDialog(!isCloudPosAvailable);
    }
  }, [configLoaded, isHybridModeActive, isCloudPosAvailable, instanceType]);

  useEffect(() => {
    setBasketIsEmpty(basketItems.length === 0 && basketCoupons.length === 0 && !selectedOrderCustomer);
  }, [selectedOrderCustomer, basketItems, basketCoupons]);

  useEffect(() => {
    if (phoneNumberCalling && basketIsEmpty) {
      dispatch(setPhoneNumberSearchQuery(phoneNumberCalling));
      dispatch(clearPhoneNumberCalling());
    }
  }, [phoneNumberCalling, basketIsEmpty, dispatch]);

  const acceptPhoneCall = useCallback(
    (phoneNumberAccepted: string): void => {
      dispatch(restartOrder());
      dispatch(setPhoneNumberSearchQuery(phoneNumberAccepted));
      dispatch(clearPhoneNumberCalling());
    },
    [dispatch],
  );

  const ignorePhoneCall = useCallback((): void => {
    dispatch(clearPhoneNumberCalling());
  }, [dispatch]);

  const showAcceptPhonecallDialog = phoneNumberCalling !== undefined && !basketIsEmpty;

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router
        // enable relevant stable future feature flags already to take advantage of a more responsive UI, remove warnings and ease transition to v7
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        {theme && (
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <FiscalContextProvider>
                <DevModeProvider>
                  <CssBaseline />

                  <AppInitializationWrapper
                    fallback={<StartupWrapper />}
                    reactPlugin={reactPlugin}
                    browserHistory={browserHistory}
                  >
                    <>
                      <Routes />
                      <AlertSnackbar />
                      <AlertModal />
                      <ConnectionLostDialog
                        open={openConnectionLostDialog}
                        offlineModeEnabled={offlineModeEnabled}
                      />
                      <ConnectionReestablishedDialog
                        open={openConnectionReestablishedDialog && !showFirstTimeLaunch}
                      />
                      {instanceType === 'Central' && (
                        <ClearBasketOnPhonecallDialog
                          open={showAcceptPhonecallDialog}
                          phoneNumber={phoneNumberCalling}
                          onCallAccepted={acceptPhoneCall}
                          onCallIgnored={ignorePhoneCall}
                        />
                      )}
                    </>
                  </AppInitializationWrapper>
                </DevModeProvider>
              </FiscalContextProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        )}
      </Router>
    </AppInsightsContext.Provider>
  );
};

export default withAITracking(reactPlugin, App, undefined, 'appInsightsContainer');

import React, { useMemo } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ItemQuantity from 'components/Intake/Receipt/Item/ItemQuantity/ItemQuantity';
import { ToppingSelection } from 'typings/Products';
import { Add, DeleteOutline } from '@mui/icons-material';
import DisabledOverlay from 'components/Shared/DisabledOverlay/DisabledOverlay';
import buildClasses from './ToppingCard.css';

type ToppingCardProps = {
  topping: ToppingSelection;
  toppingQuantity: number;
  maxQuantity: number;
  onToppingChange: (toppingId: ToppingSelection) => void;
  disableAdding?: boolean;
};

const ToppingCard: React.FC<ToppingCardProps> = ({
  topping,
  maxQuantity,
  onToppingChange,
  disableAdding,
  toppingQuantity,
}) => {
  const { classes, cx } = buildClasses();
  const { sizing, spacing, palette } = useTheme();
  function toppingClicked(): void {
    onToppingChange(topping);
  }

  const canBeOnlyRemoved = useMemo(() => {
    return toppingQuantity === maxQuantity || (toppingQuantity > 0 && disableAdding);
  }, [toppingQuantity]);

  return (
    <div className={cx(classes.toppingCard, { [classes.activeCard]: toppingQuantity > 0 })}>
      <Box
        sx={{ width: sizing.full, height: sizing.full, flexDirection: 'column', justifyContent: 'space-between' }}
        onClick={toppingClicked}
        data-testid={`topping-card__button--${topping.id}`}
      >
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Box sx={{ padding: spacing(0.5) }}>
            <ItemQuantity
              quantity={toppingQuantity}
              variant="round"
              colorVariant={toppingQuantity === 0 ? 'transparent' : 'black'}
              size="sm"
            />
          </Box>
          <Button
            data-testid={`product_set_topping_${topping.id}__button--increase-quantity`}
            className={cx(classes.button, {
              [classes.removeButton]: canBeOnlyRemoved,
            })}
          >
            {canBeOnlyRemoved ? <DeleteOutline /> : <Add />}
          </Button>
        </Box>
        <Typography
          variant="body2"
          sx={{ color: palette.black.main, wordBreak: 'break-word', padding: spacing(1) }}
        >
          {topping.name}
        </Typography>
      </Box>
      <DisabledOverlay show={disableAdding && !toppingQuantity} />
    </div>
  );
};

export default ToppingCard;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { posApiUrls } from 'API/PosApi';
import axios from 'axios';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { RootState } from 'stores';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { setSelectedCustomerAddress } from 'stores/Customer/customer.slice';
import { ProfileAddressDto, setActivePaymentMethod, setIntakeCustomer } from 'stores/Intake';
import { getPaymentMethodsToShow } from 'stores/Intake/intake.selector';
import {
  AddressBookEntry,
  CustomerCreditDetails,
  CustomerSearchResultItem,
  ProfileAddress,
} from 'stores/Intake/intakeTypes';
import { getAllRewards } from 'stores/Loyalty/loyalty.thunk-actions';
import { getPaymentMethods } from 'stores/Payments/payments.thunk-actions';
import {
  CheckCustomerCanPayOnAccountRequest,
  GetCustomerAddressesRequest,
  PayOnAccountCheckResult,
} from 'typings/Customer';
import { PaymentMethodCode } from 'typings/Payments';
import { isValidAxiosResponse } from 'typings/type-guards';
import { removeAddressesDuplicates } from 'utils/customer/customerUtils';
import { getFeatureFlags, getPaymentMethodsConfiguration, getPayOnAccountCode } from '../Config/config.selector';
import { getSelectedStore } from '../Store/store.selectors';

export const checkCurrentCustomerCanPayOnAccount = createAsyncThunk<
  PayOnAccountCheckResult | undefined,
  void,
  { state: RootState }
>('[CUSTOMER]/checkCustomerCanPayOnAccount', async (_, { getState }) => {
  const { intake, basket, customer } = getState();

  const featureFlags = getFeatureFlags(getState());

  if (!intake.selectedOrderCustomer?.profile?.id) return undefined;
  if (!featureFlags.OfflineModule_PayOnAccountEnabled) return undefined;

  const request = {
    customerId: intake.selectedOrderCustomer?.profile?.id as number,
    addressId: customer.customerAddresses.find((addr) => addr.isSelected)?.id,
    orderValue: basket.basketData?.summary?.total ?? 0,
    pickupType: intake.activeDeliveryType,
  } as CheckCustomerCanPayOnAccountRequest;

  const payOnAccountCheckResult = await axios.get<PayOnAccountCheckResult>(
    posApiUrls.CUSTOMER_PAY_ON_ACCOUNT_VALIDATION(
      request.customerId,
      request.orderValue,
      request.pickupType,
      request.addressId,
    ),
  );

  return payOnAccountCheckResult.data;
});

export const checkSpecifiedCustomerCanPayOnAccount = createAsyncThunk<
  PayOnAccountCheckResult | undefined,
  CheckCustomerCanPayOnAccountRequest,
  { state: RootState }
>('[CUSTOMER]/checkSpecifiedCustomerCanPayOnAccount', async (request, { dispatch }) => {
  const payOnAccountCheckResult = await axios.get<PayOnAccountCheckResult>(
    posApiUrls.CUSTOMER_PAY_ON_ACCOUNT_VALIDATION(
      request.customerId,
      request.orderValue,
      request.pickupType,
      request.addressId,
    ),
  );
  return payOnAccountCheckResult.data;
});

export const checkBasketResultChangesCustomerPayOnAccount = createAsyncThunk<
  void,
  CheckCustomerCanPayOnAccountRequest,
  { state: RootState }
>('[CUSTOMER]/checkBasketResultChangesCustomerPayOnAccount', async (request, { dispatch }) => {
  const data = await dispatch(checkSpecifiedCustomerCanPayOnAccount(request));
  dispatch(postCheckPayOnAccount(data.payload as PayOnAccountCheckResult));
});

export const postCheckPayOnAccount = createAsyncThunk<void, PayOnAccountCheckResult, { state: RootState }>(
  '[CUSTOMER]/selectCustomerOnOrder',
  async (checkResult, { getState, dispatch }) => {
    const { intake, payments } = getState();
    const showablePaymentMethods = getPaymentMethodsToShow(getState());
    const activeMethodAvailable = showablePaymentMethods.some((el) => el.code === intake.activePaymentMethod);
    const payOnAccountMethodCode = getPayOnAccountCode(getState());

    if (intake.activeDeliveryType === PickUpTypesValues.pickUp && checkResult.canPayOnAccount === true) {
      if (!activeMethodAvailable) dispatch(setActivePaymentMethod(payOnAccountMethodCode));
    }

    if (checkResult.canPayOnAccount === true || activeMethodAvailable) {
      return;
    }
    const paymentsWithoutPayOnAccount = payments.availablePaymentMethods.filter(
      (el) => el.code !== payOnAccountMethodCode,
    );
    dispatch(setActivePaymentMethod(paymentsWithoutPayOnAccount[0].code as PaymentMethodCode));
  },
);

export const selectCustomerOnOrder = createAsyncThunk<void, CustomerSearchResultItem, { state: RootState }>(
  '[CUSTOMER]/selectCustomerOnOrder',
  async (customer, { getState, dispatch }) => {
    dispatch(setIntakeCustomer(customer));

    const { intake } = getState();
    const featureFlags = getFeatureFlags(getState());

    if (featureFlags.OfflineModule_CustomerCreditUsage) {
      await dispatch(getCustomerCreditDetails(customer.id));
    }

    const selectedStore = getSelectedStore(getState());

    if (featureFlags.OfflineModule_ShowRewardsOnPos) {
      await dispatch(
        getAllRewards({
          customerId: customer.id,
          pickupType: intake.activeDeliveryType,
          storeId: selectedStore?.id ?? 0,
        }),
      );
    }

    if (customer.profileAddress) {
      await dispatch(
        getCustomerAddresses({
          customerId: customer.id as number,
          profileType: customer.profileType as string,
          includeCompanyAddresses: featureFlags.OfflineModule_PayOnAccountEnabled,
        }),
      );
    }

    if (customer.companyId !== undefined) {
      await dispatch(checkCurrentCustomerCanPayOnAccount());
      const { customer: customerAfterUpdate } = getState();

      if (customerAfterUpdate.customerCanPayOnAccount) {
        await dispatch(
          getPaymentMethods(
            intake.activeDeliveryType
              ? {
                  pickupType: intake.activeDeliveryType,
                  storeId: selectedStore?.id ?? undefined,
                }
              : undefined,
          ),
        );

        const { payments: updatedPayments } = getState();
        const paymentMethodsConfiguration = getPaymentMethodsConfiguration(getState());
        const payOnAccountAvailable = updatedPayments.availablePaymentMethods.some(
          (pm) => pm.code === paymentMethodsConfiguration?.payOnAccountMethodCode,
        );
        if (payOnAccountAvailable) {
          dispatch(setActivePaymentMethod(paymentMethodsConfiguration?.payOnAccountMethodCode));
          dispatch(recalculateBasket({}));
        }
      }
    }
  },
);

export const getCustomerAddresses = createAsyncThunk<
  AddressBookEntry[],
  GetCustomerAddressesRequest,
  { state: RootState }
>('[CUSTOMER]/getCustomerCompanyAddresses', async (request) => {
  const privateAddressesResponse = await axios.get<ProfileAddressDto[]>(
    posApiUrls.CUSTOMER_ADDRESSES(request.customerId, request.profileType),
  );
  const privateAddresses = privateAddressesResponse?.data ?? [];
  let customerCompanyAddresses: ProfileAddressDto[] = [];

  if (request.includeCompanyAddresses) {
    const customerCompanyAddressesResponse = await axios.get<ProfileAddressDto[]>(
      posApiUrls.GET_CUSTOMER_COMPANY_ADDRESSES(request.customerId),
    );
    customerCompanyAddresses = customerCompanyAddressesResponse?.data ?? [];
  }

  const selectedPrivateAddress = privateAddresses.find((addr) => addr.isSelected);
  const selectedCompanyAddress = customerCompanyAddresses.find((addr) => addr.isSelected);
  const newCompanyAddresses = [...customerCompanyAddresses];

  if (
    selectedPrivateAddress &&
    selectedCompanyAddress &&
    selectedPrivateAddress.fullAddress !== selectedCompanyAddress.fullAddress
  ) {
    const indexOfSelectedCompanyAddress = customerCompanyAddresses.indexOf(selectedCompanyAddress);
    newCompanyAddresses[indexOfSelectedCompanyAddress] = { ...selectedCompanyAddress, isSelected: false };
  }

  const newAddresses = removeAddressesDuplicates(privateAddresses, newCompanyAddresses);
  const anyPreselected = newAddresses.some((el) => el.isSelected);

  const addressesWithSelectedOne = newAddresses.map((x, index) => {
    return {
      ...x,
      isSelected: anyPreselected ? x.isSelected : index === 0,
      streetNumberAddition: x.streetNumberExtension,
    } as AddressBookEntry;
  });
  return addressesWithSelectedOne;
});

export const selectCustomerAddress = createAsyncThunk<void, ProfileAddress, { state: RootState }>(
  '[CUSTOMER]/selectCustomerAddress',
  async (addressToSelect, { dispatch }) => {
    dispatch(setSelectedCustomerAddress(addressToSelect));
    await dispatch(checkCurrentCustomerCanPayOnAccount());
    await dispatch(recalculateBasket({}));
  },
);

export const getCustomerAddressesByPhone = createAsyncThunk(
  '[CUSTOMER]/getCustomerAddressesByPhone',
  async (phoneNumber: string) => {
    const result = await axios.get<AddressBookEntry[]>(posApiUrls.CUSTOMER_ADDRESSES_SEARCH(phoneNumber));
    return result.data;
  },
);

export const getCustomerCreditDetails = createAsyncThunk<
  CustomerCreditDetails | undefined,
  number,
  { state: RootState }
>('[CUSTOMER]/getCustomerCreditDetails', async (customerId) => {
  const result = await axios.get<CustomerCreditDetails>(posApiUrls.CUSTOMER_CREDIT_DETAILS(customerId.toString()));

  if (isValidAxiosResponse(result) && result?.data?.total > 0) {
    return result.data;
  }
  return undefined;
});

export const getCustomerSearchResults = createAsyncThunk<
  { results: CustomerSearchResultItem[]; noMoreResults: boolean },
  { customerSearchQuery: string; currentPageIndex: number; loadAdditional: boolean },
  { state: RootState }
>(
  '[CUSTOMER]/getCustomerSearchResults',
  async ({ customerSearchQuery, currentPageIndex, loadAdditional }, { getState }) => {
    const result = await axios.get<CustomerSearchResultItem[]>(
      posApiUrls.CUSTOMER_SEARCH(customerSearchQuery, currentPageIndex),
    );

    const { customerSearchResults } = getState().customer;

    if (customerSearchResults.length === 0) {
      return { results: result.data, noMoreResults: result.data?.length === 0 };
    }

    const newCollection: CustomerSearchResultItem[] = [];

    if (loadAdditional) {
      newCollection.push(...customerSearchResults);
    }
    const newCustomers =
      result.data.filter((cst) => !newCollection.some((nc) => nc.username === cst.username)) ?? [];

    newCollection.push(...newCustomers);

    return { results: newCollection, noMoreResults: newCustomers.length === 0 };
  },
);

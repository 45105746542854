import { PaymentConfiguration } from 'stores/Config/configTypes';

export function getBillDenominations(): number[] {
  return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 70, 100];
}
export function getCoinDenominations(): number[] {
  return [0.01, 0.02, 0.05, 0.1, 0.5, 1, 2];
}
export function getAllDenominations(): number[] {
  return getCoinDenominations().concat(getBillDenominations());
}

function formatValueToMoney(value: number, paymentSettings: PaymentConfiguration): string {
  const val = value !== undefined ? value : 0;
  if (paymentSettings.culture && paymentSettings.currencyISO) {
    return val.toLocaleString(paymentSettings.culture, {
      style: 'currency',
      currency: paymentSettings.currencyISO,
    });
  }
  return '';
}

export function formatToDisplay(value: number, paymentSettings: PaymentConfiguration): string {
  return formatValueToMoney(value, paymentSettings);
}

export function formatToEdit(value: number, paymentSettings: PaymentConfiguration): string {
  return formatValueToMoney(value, paymentSettings).replace(paymentSettings.currencySymbol, '').trim();
}

export function formatDenominationToDisplay(value: number, paymentSettings: PaymentConfiguration): string {
  return formatToDisplay(value, paymentSettings);
}

export function isNumber(value: string, paymentSettings: PaymentConfiguration): boolean {
  const valueWithoutThousandSeparator = value.replace(paymentSettings.thousandsSeparator, '');
  const valueFormatted = valueWithoutThousandSeparator.replace(paymentSettings.decimalSeparator, '.');
  // eslint-disable-next-line no-restricted-globals
  return value != null && value !== '' && !isNaN(Number(valueFormatted));
}

export function makePositiveValue(value: number): number {
  if (value < 0) {
    return value * -1;
  }
  return value;
}

export function numberFromMoney(value: string, paymentSettings: PaymentConfiguration): number {
  const decimalSeparatorChunk = getRegexLiterals(paymentSettings.decimalSeparator);
  const thousandsSeparatorChunk = getRegexLiterals(paymentSettings.thousandsSeparator);
  if (!value) return 0;
  const valueToParse = value.replace(
    new RegExp(`${decimalSeparatorChunk}|${thousandsSeparatorChunk}`, 'g'),
    (val) => (val === paymentSettings.decimalSeparator ? '.' : ''),
  );

  return parseFloat(valueToParse);
}

export function getRegexLiterals(sign: string): string {
  switch (sign.trim()) {
    case '.':
      return '\\.';
    case '':
      return '\\s';
    default:
      return sign;
  }
}

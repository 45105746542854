import React from 'react';
import { Jump } from 'themes/animations.css';
import { keyframes } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export interface JumpingDotsProps {
  colorOverwrite?: string;
}

const buildClasses = makeStyles<JumpingDotsProps>()(({ palette, spacing }, { colorOverwrite }) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dot: {
      background: palette.secondary.main,
      height: spacing(1),
      width: spacing(1),
      borderRadius: spacing(0.5),
      marginRight: spacing(0.75),
      '&:nth-last-of-type(1)': {
        animation: `${keyframes`${Jump(
          spacing(0.75),
          colorOverwrite ?? palette.black.main,
        )}`} 1.2s .6s ease-in infinite`,
      },
      '&:nth-last-of-type(2)': {
        animation: `${keyframes`${Jump(
          spacing(0.75),
          colorOverwrite ?? palette.black.main,
        )}`} 1.2s .3s ease-in infinite`,
      },
      '&:nth-last-of-type(3)': {
        animation: `${keyframes`${Jump(
          spacing(0.75),
          colorOverwrite ?? palette.black.main,
        )}`} 1.2s 0s ease-in infinite`,
      },
    },
  };
});

const JumpingDotsLoader: React.FC<JumpingDotsProps> = ({ colorOverwrite }) => {
  const { classes } = buildClasses({
    colorOverwrite,
  });
  return (
    <div className={classes.root}>
      <span className={classes.dot} />
      <span className={classes.dot} />
      <span className={classes.dot} />
    </div>
  );
};

export default JumpingDotsLoader;
